import React from 'react'

const DataSection = ({ title, data, noDataMessage, color }) => {
    return (
        <div className="custom-mb">
            {data?.length > 0 ? (
                <>
                    <h4 className="custom-mb" style={{color: `${title === 'Gambling websites' ? 'red' : 'black'}`}}>{title}</h4>
                    {data?.map((val, index) => (
                        <div key={index}>
                            <p className="custom-mb"><span className='card-span'>Date:</span>{val?.date}</p>
                            {
                                val?.payment_method && (
                                    <p className="custom-mb"><span className='card-span'>Payment method:</span>{val.payment_method}</p>
                                )
                            }
                            <p className="custom-mb"><span className='card-span'>Description:</span>{val?.description}</p>
                            <p className="custom-mb"><span className='card-span'>Paid Out:</span>{val?.paid_out || 0}</p>
                            <p className="custom-mb"><span className='card-span'>Paid In:</span>{val?.paid_in || 0}</p>
                            <br />
                        </div>
                    ))}
                </>
            ) : (
                <span className='card-span' style={{ color }}>{noDataMessage}</span>
            )}
        </div>
    );
};


export default DataSection