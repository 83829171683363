import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./docsUpload.css";
import UserNavbar from "../../components/userNavbar/UserNavbar";
import { authTokenFormData } from "../../services/token";
import { uploadDocumentsApi } from "../../services/apis/caseApi";
import {
  recievedError,
  requestSent,
  responseRecieved,
} from "../../redux/slices/utilsSlice";
import NavigationButtons from "../../components/navigationButtons/NavigationButtons";
import { ENVS } from "../../utils/constant";
import { fetchCaseDetailApi } from "../../services/apis/caseApi";

const DocsUpload = () => {
  const navigate = useNavigate();
  const loading = useSelector((state) => state.utilsObj.loading);
  const [caseDetail, setCaseDetail] = useState(null); // State to store the fetched case detail
  // const [remove_docs_path, setRemoveDocsPath] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const caseRequestId = searchParams.get("case_request_id");
  const isEdit = searchParams.get("isEdit") || false;
  const { REACT_APP_FRONTEND_IMG_PATH } = ENVS;
  const [fileData, setFileData] = useState({
    payslip_1: null,
    payslip_2: null,
    payslip_3: null,
    bank_statement_1: null,
    bank_statement_2: null,
    bank_statement_3: null,
    credit_card_statement_1: null,
    credit_card_statement_2: null,
    credit_card_statement_3: null,
    joint_account_statement_1: null,
    joint_account_statement_2: null,
    joint_account_statement_3: null,
    p60: null,
    employment_contract: null
  });

  const [fileUploadStatus, setFileUploadStatus] = useState({
    payslip_1: "Pending",
    payslip_2: "Pending",
    payslip_3: "Pending",
    bank_statement_1: "Pending",
    bank_statement_2: "Pending",
    bank_statement_3: "Pending",
    credit_card_statement_1: "Pending",
    credit_card_statement_2: "Pending",
    credit_card_statement_3: "Pending",
    joint_account_statement_1: "Pending",
    joint_account_statement_2: "Pending",
    joint_account_statement_3: "Pending",
    p60: "Pending",
    employment_contract: "Pending"
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const fileName = files[0].name;
      // validation same file not uploaded twice
      const isDuplicate = Object.values(fileUploadStatus).some((value) => {
        return value.split("Uploaded ")[1]?.trim() === fileName;
      });

      if (isDuplicate) {
        return toast.error("Same document cannot be uploaded twice");
      }

      setFileData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));

      setFileUploadStatus((prevState) => ({
        ...prevState,
        [name]: `Uploaded ${fileName}`,
      }));
    }
  };

  // check is path is a local path or server url
  const isServerUrl = (path) => {
    const splitedPath = path.split(" ")[1];
    const serverUrl = splitedPath?.startsWith("/") ?? false;

    console.log(splitedPath, serverUrl);

    return { splitedPath, serverUrl };
  };

  // remove file
  const removeFile = (name, path, isBankStatement = false, index) => {
    console.log(name, path, isBankStatement, index)
    let remove_docs_path = []
    const data = isServerUrl(path);

    setFileData((prevState) => ({
      ...prevState,
      [name]: null,
    }));

    setFileUploadStatus((prevState) => ({
      ...prevState,
      [name]: `Pending`,
    }));

    if (data.serverUrl) {
      remove_docs_path.push(data.splitedPath);
      if (isBankStatement) {
        remove_docs_path.push(caseDetail[`bank_statement_${index + 1}`]);
      }
    }
  };

  // remove extra server trailing path and return actual file name
  const getFileName = (filename) => {
    const segments = filename.split("/");
    return segments[segments.length - 1];
  };

  const handleDocsSubmit = async (e) => {
    e.preventDefault();

    for (const [key, value] of Object.entries(fileData)) {
      if (!value) {
        delete fileData[key];
      }
    }

    try {
      const token = authTokenFormData();
      const formData = new FormData();

      // payslips
      [fileData.payslip_1, fileData.payslip_2, fileData.payslip_3].map(
        (file, index) => {
          if (file) {
            formData.append(`payslip_${index + 1}`, file);
          }
        }
      );

      // bank statements
      [
        fileData.bank_statement_1,
        fileData.bank_statement_2,
        fileData.bank_statement_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`bank_statement_${index + 1}`, file);
        }
      });

      // credit card statements
      [
        fileData.credit_card_statement_1,
        fileData.credit_card_statement_2,
        fileData.credit_card_statement_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`credit_card_statement_${index + 1}`, file);
        }
      });

      // joint account statements
      [
        fileData.joint_account_statement_1,
        fileData.joint_account_statement_2,
        fileData.joint_account_statement_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`joint_account_statement_${index + 1}`, file);
        }
      });

      if (fileData.p60) {
        formData.append(`p60`, fileData.p60)
      }

      if (fileData.employment_contract) {
        formData.append(`employment_contract`, fileData.employment_contract)
      }

      dispatch(requestSent());
      const response = await uploadDocumentsApi(formData, caseRequestId, token);
      if (response.status === 200) {
        dispatch(responseRecieved());
        toast.success(response.data.message);
        if (isEdit && isEdit === 'true') {
          navigate(`/report?case_request_id=${caseRequestId}&isEdit=${isEdit}`);
        } else {
          console.log('isEditFalse')
          navigate(`/report?case_request_id=${caseRequestId}`);
        }
        return;
      }
    } catch (error) {
      console.log("error=>", error);
      dispatch(recievedError());
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate("/login");
      }
      return toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchCaseDetail();
    }
  }, []);

  const updateFileUploadStatus = (data) => {
    // for payslips
    [data.payslip_1, data.payslip_2, data.payslip_3].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`payslip_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    // for bank statements
    [data.bank_statement_4, data.bank_statement_5, data.bank_statement_6].map(
      (fileName, index) => {
        if (fileName) {
          setFileUploadStatus((prevState) => ({
            ...prevState,
            [`bank_statement_${index + 1}`]: `Uploaded ${fileName}`,
          }));
        }
      }
    );

    // for credit card statements
    [
      data.credit_card_statement_1,
      data.credit_card_statement_2,
      data.credit_card_statement_3,
    ].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`credit_card_statement_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    // for joint account statements
    [
      data.joint_account_statement_1,
      data.joint_account_statement_2,
      data.joint_account_statement_3,
    ].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`joint_account_statement_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    //p60
    if (data.p60) {
      setFileUploadStatus((prevState) => ({
        ...prevState,
        p60: `Uploaded ${data.p60}`,
      }));
    }

    //employment_contract
    if (data.employment_contract) {
      setFileUploadStatus((prevState) => ({
        ...prevState,
        employment_contract: `Uploaded ${data.employment_contract}`,
      }));
    }
  };



  const fetchCaseDetail = async () => {
    try {
      const token = authTokenFormData();
      dispatch(requestSent());
      const response = await fetchCaseDetailApi(caseRequestId, token);
      if (response.status === 200) {
        const data = response.data.data;
        setCaseDetail(data);
        // update file upload status
        updateFileUploadStatus(data);
        dispatch(responseRecieved());
      }
    } catch (error) {
      console.log("error=>", error);
      dispatch(recievedError(error));
    }
  };

  return (
    <>
      <UserNavbar />
      <div className="common-container-2">
        <NavigationButtons
          buttons={[
            { label: "Case Requests", isActive: false },
            { label: "Documents Upload", isActive: true },
          ]}
        />
      </div>

      <div className="create-case-container">
        <div className="create-new-case-header">
          <div>
            <img
              src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`}
              alt="backarrow"
              className="back-arrow"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/create-case?case_request_id=${caseRequestId}`)
              }
            />
          </div>
          <div>
            <h2>Upload Documents</h2>
          </div>
        </div>
        <p className="upload-doc-one-liner">
          To confirm, please upload the following documents
        </p>

        <form
          method="post"
          onSubmit={handleDocsSubmit}
          encType="multipart/form-data"
        >
          <div className="inp-files-container-parent">
            <>
              <div className="inp-files-container">
                {["payslip_1", "payslip_2", "payslip_3"].map(
                  (payslip, index) => (
                    <div className="payslip-container" key={index}>
                      <div
                        className={`${fileUploadStatus[payslip] === "Pending"
                          ? "pending"
                          : "pending uploaded"
                          }`}
                      >
                        <img
                          src={
                            fileUploadStatus[payslip] === "Pending"
                              ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                              : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                          }
                          alt="status"
                        />
                        <p className="document-name text-overflow-elipsis">
                          {getFileName(fileUploadStatus[payslip])}&nbsp;
                        </p>
                        {fileUploadStatus[payslip] !== "Pending" ? (
                          <img
                            width="15"
                            className="cursor-pointer"
                            src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                            alt="remove-file"
                            onClick={() =>
                              removeFile(payslip, fileUploadStatus[payslip])
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="doc-icon">
                        <img
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                          alt="file-inp"
                          className="cloud-img"
                        />
                        <p style={{ fontSize: "12px" }}>Payslip {index + 1}</p>
                        <div className="file-icon-btn">
                          <img
                            src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                            alt="file-inp"
                          />
                          <span>Upload pdf</span>
                        </div>
                      </div>
                      <input
                        type="file"
                        name={payslip}
                        onChange={handleFileChange}
                        className="upload-pdf-inp"
                        accept=".pdf"
                        required={
                          index === 0 && fileUploadStatus[payslip] === "Pending"
                        }
                      />
                    </div>
                  )
                )}
              </div>
              <div className="inp-files-container">
                {[
                  "bank_statement_1",
                  "bank_statement_2",
                  "bank_statement_3",
                ].map((bankStatement, index) => (
                  <div className="bankstatement-container" key={index}>
                    <p
                      className={`${fileUploadStatus[bankStatement] === "Pending"
                        ? "pending"
                        : "pending uploaded"
                        }`}
                    >
                      <img
                        src={
                          fileUploadStatus[bankStatement] === "Pending"
                            ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                            : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                        }
                        alt="status"
                      />
                      <p className="document-name text-overflow-elipsis">
                        {getFileName(fileUploadStatus[bankStatement])}&nbsp;
                      </p>
                      {fileUploadStatus[bankStatement] !== "Pending" ? (
                        <img
                          width="15"
                          className="cursor-pointer"
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                          alt="remove-file"
                          onClick={() =>
                            removeFile(
                              bankStatement,
                              fileUploadStatus[bankStatement],
                              true,
                              index
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </p>

                    <div className="doc-icon">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                        alt="file-inp"
                        className="cloud-img"
                      />
                      <p style={{ fontSize: "12px" }}>
                        Bank Statement {index + 1}
                      </p>
                      <div className="file-icon-btn">
                        <img
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                          alt="file-inp"
                        />
                        {index === 0 || index === 1 || index === 2 ? (
                          /** Fist three containers will be for csvs of bank statement */
                          <span>Upload pdf</span>
                        ) : (
                          /** Last three containers will be for pdfs of bank statement */
                          <span>Upload csv</span>
                        )}
                      </div>
                    </div>
                    <input
                      type="file"
                      name={bankStatement}
                      onChange={handleFileChange}
                      className="upload-pdf-inp"
                      accept={
                        index === 0 || index === 1 || index === 2
                          ? ".pdf"
                          : ".csv"
                      }
                      required={
                        index === 0 &&
                        fileUploadStatus[bankStatement] === "Pending"
                      }
                    />
                  </div>
                ))}
              </div>
              <div className="inp-files-container">
                {[
                  "credit_card_statement_1",
                  "credit_card_statement_2",
                  "credit_card_statement_3",
                ].map((creditCardStatement, index) => (
                  <div className="payslip-container" key={index}>
                    <div
                      className={`${fileUploadStatus[creditCardStatement] === "Pending"
                        ? "pending"
                        : "pending uploaded"
                        }`}
                    >
                      <img
                        src={
                          fileUploadStatus[creditCardStatement] === "Pending"
                            ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                            : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                        }
                        alt="status"
                      />
                      <p className="document-name text-overflow-elipsis">
                        {getFileName(fileUploadStatus[creditCardStatement])}
                        &nbsp;
                      </p>
                      {fileUploadStatus[creditCardStatement] !== "Pending" ? (
                        <img
                          width="15"
                          className="cursor-pointer"
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                          alt="remove-file"
                          onClick={() =>
                            removeFile(
                              creditCardStatement,
                              fileUploadStatus[creditCardStatement]
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="doc-icon">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                        alt="file-inp"
                        className="cloud-img"
                      />
                      <p style={{ fontSize: "12px" }}>
                        Credit Card Statment {index + 1}
                      </p>
                      <div className="file-icon-btn">
                        <img
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                          alt="file-inp"
                        />
                        <span>Upload pdf</span>
                      </div>
                    </div>
                    <input
                      type="file"
                      name={creditCardStatement}
                      onChange={handleFileChange}
                      className="upload-pdf-inp"
                      accept=".pdf"
                    />
                  </div>
                ))}
              </div>
              <div className="inp-files-container">
                {[
                  "joint_account_statement_1",
                  "joint_account_statement_2",
                  "joint_account_statement_3",
                ].map((jointAccountStatement, index) => (
                  <div className="bankstatement-container" key={index}>
                    <p
                      className={`${fileUploadStatus[jointAccountStatement] === "Pending"
                        ? "pending"
                        : "pending uploaded"
                        }`}
                    >
                      <img
                        src={
                          fileUploadStatus[jointAccountStatement] === "Pending"
                            ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                            : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                        }
                        alt="status"
                      />
                      <p className="document-name text-overflow-elipsis">
                        {getFileName(fileUploadStatus[jointAccountStatement])}
                        &nbsp;
                      </p>
                      {fileUploadStatus[jointAccountStatement] !== "Pending" ? (
                        <img
                          width="15"
                          className="cursor-pointer"
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                          alt="remove-file"
                          onClick={() =>
                            removeFile(
                              jointAccountStatement,
                              fileUploadStatus[jointAccountStatement]
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </p>

                    <div className="doc-icon">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                        alt="file-inp"
                        className="cloud-img"
                      />
                      <p style={{ fontSize: "12px" }}>
                        Joint Acc Statement {index + 1}
                      </p>
                      <div className="file-icon-btn">
                        <img
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                          alt="file-inp"
                        />
                        {index === 0 || index === 1 || index === 2 ? (
                          /** Fist three containers will be for csvs of bank statement */
                          <span>Upload pdf</span>
                        ) : (
                          /** Last three containers will be for pdfs of bank statement */
                          <span>Upload csv</span>
                        )}
                      </div>
                    </div>
                    <input
                      type="file"
                      name={jointAccountStatement}
                      onChange={handleFileChange}
                      className="upload-pdf-inp"
                      accept={
                        index === 0 || index === 1 || index === 2
                          ? ".pdf"
                          : ".csv"
                      }
                    />
                  </div>
                ))}
              </div>

              <div className="inp-files-container">
                {/** P60 */}
                <div className="bankstatement-container">
                  <p
                    className={`${fileUploadStatus.p60 === "Pending"
                      ? "pending"
                      : "pending uploaded"
                      }`}
                  >
                    <img
                      src={
                        fileUploadStatus.p60 === "Pending"
                          ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                          : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                      }
                      alt="status"
                    />
                    <p className="document-name text-overflow-elipsis">
                      {getFileName(fileUploadStatus.p60)}
                      &nbsp;
                    </p>
                    {fileUploadStatus.p60 !== "Pending" ? (
                      <img
                        width="15"
                        className="cursor-pointer"
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                        alt="remove-file"
                        onClick={() =>
                          removeFile(
                            'p60',
                            fileUploadStatus.p60
                          )
                        }
                      />
                    ) : (
                      ""
                    )}
                  </p>

                  <div className="doc-icon">
                    <img
                      src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                      alt="file-inp"
                      className="cloud-img"
                    />
                    <p style={{ fontSize: "12px" }}>
                      P60
                    </p>
                    <div className="file-icon-btn">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                        alt="file-inp"
                      />
                      <span>Upload pdf</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    name="p60"
                    onChange={handleFileChange}
                    className="upload-pdf-inp"
                    accept=".pdf"
                  />
                </div>
                {/**P60 */}

                {/**Employment Contract */}
                <div className="bankstatement-container">
                  <p
                    className={`${fileUploadStatus.employment_contract === "Pending"
                      ? "pending"
                      : "pending uploaded"
                      }`}
                  >
                    <img
                      src={
                        fileUploadStatus.employment_contract === "Pending"
                          ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                          : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                      }
                      alt="status"
                    />
                    <p className="document-name text-overflow-elipsis">
                      {getFileName(fileUploadStatus.employment_contract)}
                      &nbsp;
                    </p>
                    {fileUploadStatus.employment_contract !== "Pending" ? (
                      <img
                        width="15"
                        className="cursor-pointer"
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                        alt="remove-file"
                        onClick={() =>
                          removeFile(
                            'employment_contract',
                            fileUploadStatus.employment_contract
                          )
                        }
                      />
                    ) : (
                      ""
                    )}
                  </p>

                  <div className="doc-icon">
                    <img
                      src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                      alt="file-inp"
                      className="cloud-img"
                    />
                    <p style={{ fontSize: "12px" }}>
                      Emp. Contract
                    </p>
                    <div className="file-icon-btn">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                        alt="file-inp"
                      />
                      <span>Upload pdf</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    name="employment_contract"
                    onChange={handleFileChange}
                    className="upload-pdf-inp"
                    accept=".pdf"
                  />
                </div>
                {/**Employment Contract */}
              </div>


            </>
            {loading ? (
              <>
                <div className="cs-dot-loader"></div>
                <h3 className="wait-msg">Please wait we are extracting the uploaded files.</h3>
              </>
            ) : (
              <button
                className="save-case-btn"
                style={{ display: "block", margin: "0 auto" }}
                disabled={loading}
              >
                Confirm & continue
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default DocsUpload;
