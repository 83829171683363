import axios from "axios";
import { URI_PREFIX, ENVS } from "../../utils/constant";

const {REACT_APP_REQUEST_URL, REACT_APP_ENVIRONMENT} = ENVS;

const REQUEST_URL = REACT_APP_REQUEST_URL;
const env = REACT_APP_ENVIRONMENT;
const prefix = URI_PREFIX[env];

export const createCaseApi = async (data, token) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/case-request/create`, data, token);
    return response;
}

export const uploadDocumentsApi = async (data, id, token) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/case-request/upload-documents?case_request_id=${id}`, data, token);
    return response;
}

export const fetchSelfCaseRequestApi = async (pageNo, token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/case-request/self-requests?page=${pageNo}`, token);
    return response;
}

export const fetchRecentCasesApi = async (token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/case-request/recent-cases`, token);
    return response;
}

export const fetchCaseDetailApi = async (id, token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/case-request/detail?case_request_id=${id}`, token);
    return response;
}

export const generateCaseReportApi = async (id, token, isEdit) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/case-request/generate-report?case_request_id=${id}&isEdit=${isEdit}`, token);
    return response;
}

export const fetchCaseBankStatementTransactionsApi = async (id, filterTransactionData, token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/case-request/bs-transactions?case_request_id=${id}&payment_method=${filterTransactionData.payment_method}&amount=${filterTransactionData.amount}&statement_type=${filterTransactionData.statement_type}`, token);
    return response;
}

export const updateCaseApi = async (data, id, token) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/case-request/update?case_request_id=${id}`, data, token);
    return response;
}

export const updateBankStatementTransactionComment = async (transactionId, data, token) => {
    const response = await axios.patch(`${REQUEST_URL}${prefix}/api/case-request/bs-transactions/${transactionId}/comment`, data, token);
    return response;
}