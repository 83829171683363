import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import UserNavbar from '../../components/userNavbar/UserNavbar'
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { fetchCaseDetailApi } from '../../services/apis/caseApi';
import { authToken } from '../../services/token';
import Loader from '../../components/loader/Loader';
import moment from 'moment';
import './commonCases.css'
import DataSection from '../../components/dataSection/DataSection';
import { ENVS, DETAIL_MATCH_STATUS, REPORT_CRITERIAS } from '../../utils/constant'

const CaseDetail = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = authToken();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const caseId = searchParams.get('id');
  const loading = useSelector((state) => state.utilsObj.loading);
  const [caseDetail, setCaseDetail] = useState(null); // State to store the fetched case detail
  // const [payslipReport, setPaySlipReport] = useState([]);
  // const [gamblingAndUtlityData, setGamblingAndUtlityData] = useState({});
  // const [netPayMatchData, setNetPayMatchData] = useState([]);
  // const [nameAndAddress, setNameAndAddress] = useState([]);
  // const [p60Data, setP60Data] = useState({});
  const [reports, setReports] = useState({
    payslipReport: [],
    gamblingAndUtlityData: {},
    netPayMatchData: [],
    nameAndAddress: [],
    p60Data: {},
    employmentContract: {}
  });
  const userId = Number(localStorage.getItem('userId'));
  const errorNA = <span className='error-red'>NA</span>;

  useEffect(() => {
    fetchCaseDetail();
  }, []);

  const fetchCaseDetail = async () => {
    try {
      dispatch(requestSent());
      const response = await fetchCaseDetailApi(caseId, token);
      if (response.status === 200) {
        const data = response.data.data;
        setCaseDetail(data);
        const newReports = {
          payslipReport: [],
          gamblingAndUtlityData: {},
          netPayMatchData: [],
          nameAndAddress: [],
          p60Data: {},
          employmentContract: {}
        };
        data.CaseReports.forEach(report => {
          const parsedResult = JSON.parse(report.result);
          if (report.criteria === REPORT_CRITERIAS.PAYSLIP_DATA) {
            newReports.payslipReport = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.PAYSLIP_AND_BANKSTATEMENT_COMPARISION) {
            newReports.gamblingAndUtlityData = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.MATCHING_NET_PAYS) {
            newReports.netPayMatchData = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.MATCHING_NAMES_AND_ADDRESSES) {
            newReports.nameAndAddress = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.P60_DATA) {
            newReports.p60Data = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.EMPLOYMENT_CONTRACT) {
            newReports.employmentContract = parsedResult
          }
        });
        setReports(newReports);
        dispatch(responseRecieved());
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
    }
  }

  function validatePaymentMethod(paySlip) {
    try {
      const payMethod = paySlip.pay_method.toLowerCase();
      const employeeNo = Number(
        paySlip.employee_no?.toString().match(/\d+/)[0]
      );

      if (payMethod === "bacs" || payMethod === "bgc" || ((payMethod === "faster payment" || payMethod === "cr") && employeeNo < 50)) {
        return paySlip.pay_method;
      }

      return <span className='error-red'>{paySlip.pay_method} (Neither bacs nor bgc and also if is faster payment or cr than employeeNo is greater than 50)</span>;
    } catch (error) {
      return errorNA;
    }
  }

  function validateNicEmpNoTaxCode(key1, key2, key3, index, keyName) {
    try {
      const keys = [key1, key2];
      if (key3) keys.push(key3);

      const val = keys[index];
      if (!val) {
        return errorNA;
      }

      if (keyName === 'Tax code' && (new Set(keys).size !== 1 || !/1250L/i.test(val))) {
        return <span className='error-red'>{val} ({keyName} is different / tax code is not 1250L )</span>;
      }

      if (new Set(keys).size !== 1) {
        return <span className='error-red'>{val} ({keyName} is different)</span>;
      }

      return val;
    } catch (error) {
      return errorNA;
    }
  }
  const { payslipReport, gamblingAndUtlityData, netPayMatchData, nameAndAddress, p60Data, employmentContract } = reports;
  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  return (
    <>
      {
        loading ?
          (
            <Loader />
          ) : (
            <>
              <UserNavbar />
              <div className="common-page">

                <div className='account-box-header'>
                  <div className='flex-div'>
                    <h1 className='flex-center-cs' style={{ marginLeft: '15px' }}>Case Information <img onClick={() => navigate(`/docs-upload?case_request_id=${caseId}&isEdit=true`)} style={{ marginLeft: '15px', cursor: "pointer" }} src={`${ENVS.REACT_APP_FRONTEND_IMG_PATH}/assets/icons/edit.png`} width="25" alt="edit-case" /></h1>
                  </div>
                  <div>
                    <p className='back-to-home' onClick={() => navigate('/home')}>Back to home</p>
                  </div>
                </div>

                {caseDetail ? (
                  <>
                    <div className="case-parent">
                      <div className="case-child-one">
                        <div className="case-card">
                          <div className="case-card-left">
                            <div className='custom-mb'><span className='card-span'>Name:</span>{caseDetail.name}</div>
                            <div className='custom-mb'><span className='card-span'>Broker Name:</span>{caseDetail.broker_name || errorNA}</div>
                            <div className='custom-mb'><span className='card-span'>Employer Name:</span>{caseDetail.employer_name || errorNA}</div>
                            <div className='custom-mb'><span className='card-span'>Date Created:</span>
                              {moment(caseDetail.createdAt).format('llll')}
                            </div>
                            {caseDetail.CaseAddresses && (
                              <div>
                                <div className='custom-mb'> <span className='card-span' style={{ textDecoration: 'underline' }}>Case Addresses</span></div>
                                {caseDetail.CaseAddresses.map((address, index) => (
                                  <div key={index} className='custom-mb'>
                                    <p className='custom-mb'><span className='card-span'>Address {index + 1}:</span></p>
                                    <p><span className='card-span'>Place</span>{address.address}</p>
                                    <p style={{ textTransform: 'capitalize' }}><span className='card-span'>Address Type: </span>{address.address_type}</p>
                                    <p><span className='card-span'>Move in Date:</span> {address.move_in_date}</p>
                                    <p><span className='card-span'>Move out Date:</span> {address.move_out_date}</p>
                                  </div>
                                ))}
                              </div>
                            )}
                            {
                              caseDetail.approval_status === 'pending' && caseDetail.requester_id === userId && (
                                <div className='custom-mb'>
                                  <br />
                                  <p className='card-span custom-mb'>You have not uploaded documents for this case *</p>
                                  <button
                                    className='save-case-btn'
                                    onClick={() => navigate(`/docs-upload?case_request_id=${caseId}`)}
                                  >Upload Documents
                                  </button>
                                </div>
                              )
                            }
                          </div>
                          <div className="case-card-right">
                            <div className="card-score">
                              <h1>Score</h1>
                              <h1>{caseDetail.score === null ? 'Not Available' : `${caseDetail.score}%`}</h1>
                            </div>
                          </div>
                        </div>
                      </div>

                      {
                        caseDetail.score &&
                        <div className="case-child-two">
                          <div className="case-card">
                            <div className="case-card-left">
                              {/* <h4 className="custom-mb error-red"></h4> */}
                              {
                                payslipReport.map((val, index) => {

                                  let nameMatch, addressMatch;
                                  if (val?.matching && val?.matching?.length > 0) {
                                    val?.matching?.forEach((val) => {
                                      if (val?.key === 'name') {
                                        nameMatch = val?.match
                                      } else if (val?.key === 'address') {
                                        addressMatch = val?.match
                                      }
                                    })
                                  }
                                  return (
                                    <div key={index}>
                                      <h4 className="custom-mb">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Payslip Data</h4>
                                      <div className='custom-mb'><span className='card-span'>Name on payslip:</span><span className={`${nameMatch === DETAIL_MATCH_STATUS.FULL ? 'full-match-text' : nameMatch === DETAIL_MATCH_STATUS.PARTIAL ? 'partial-match-text' : nameMatch === DETAIL_MATCH_STATUS.None ? 'none-match-text' : ''}`}>{val?.payslip_owner_name || errorNA}</span></div>
                                      <div className='custom-mb'><span className='card-span'>Employee Number on payslip:</span>{validateNicEmpNoTaxCode(payslipReport[0]?.employee_no, payslipReport[1]?.employee_no, payslipReport[2]?.employee_no, index, 'Employee Number')}</div>
                                      <div className='custom-mb'><span className='card-span'>Address on payslip:</span><span className={`${addressMatch === DETAIL_MATCH_STATUS.FULL ? 'full-match-text' : addressMatch === DETAIL_MATCH_STATUS.PARTIAL ? 'partial-match-text' : addressMatch === DETAIL_MATCH_STATUS.None ? 'none-match-text' : ''}`}>{val?.payslip_owner_address || errorNA}</span></div>
                                      <div className='custom-mb'><span className='card-span'>Date:</span>{val?.date || errorNA}</div>
                                      <div className='custom-mb'><span className='card-span'>Pay method on payslip:</span>
                                        {validatePaymentMethod(val)}
                                      </div>
                                      <div className='custom-mb'><span className='card-span'>Salary on payslip:</span>{val?.salary || errorNA}</div>
                                      <div className='custom-mb'><span className='card-span'>Net Pay on payslip:</span>{val?.net_pay || errorNA}</div>
                                      <div className='custom-mb'><span className='card-span'>Deduction on employees pension:</span>{val?.deductions?.employees_pension || 0}</div>
                                      <div className='custom-mb'><span className='card-span'>Deduction on employer pension:</span>{val?.deductions?.employers_pension || 0}</div>
                                      <div className='custom-mb'>
                                        <span className='card-span'>Tax code:</span>
                                        {validateNicEmpNoTaxCode(payslipReport[0]?.tax_code, payslipReport[1]?.tax_code, payslipReport[2]?.tax_code, index, 'Tax code')}
                                      </div>
                                      <div className='custom-mb'><span className='card-span'>National insurance number:</span>{validateNicEmpNoTaxCode(payslipReport[0]?.national_insurance_number, payslipReport[1]?.national_insurance_number, payslipReport[2]?.national_insurance_number, index, 'National insurance number')}</div>
                                      <div className='custom-mb'><span className='card-span'>Employee Pension:</span>{val?.employee_to_date_totals.employee_pension || errorNA}</div>
                                      <div className='custom-mb'><span className='card-span'>Paye Tax:</span>{val?.employee_to_date_totals.paye_tax || errorNA}</div>
                                      <div className='custom-mb'><span className='card-span'>Student Loans:</span>{val?.employee_to_date_totals.student_loans || errorNA}</div>
                                      <div className='custom-mb'><span className='card-span'>NIC:</span>{val?.employee_to_date_totals.nic || errorNA}</div>
                                      {val?.additional_program_a_non_pensionable && (
                                        <div className='custom-mb'>
                                          <span className='card-span'>Additional program a non pensionable::</span>{val.additional_program_a_non_pensionable}
                                        </div>
                                      )}
                                      {val?.on_call_cat_a_low_fr && (
                                        <div className='custom-mb'>
                                          <span className='card-span'>On call cat a low fr:</span>{val.on_call_cat_a_low_fr}
                                        </div>
                                      )}
                                      {val?.course_fees && (
                                        <div className='custom-mb'>
                                          <span className='card-span'>Course fees:</span>{val.course_fees}
                                        </div>
                                      )}
                                      {val?.night_duty && (
                                        <div className='custom-mb'>
                                          <span className='card-span'>Night duty:</span>{val.night_duty}
                                        </div>
                                      )}
                                      {val?.flex_pt_trans_2_arrs && (
                                        <div className='custom-mb'>
                                          <span className='card-span'>Flex pt trans 2 arrs:</span>{val.flex_pt_trans_2_arrs}
                                        </div>
                                      )}
                                      {val?.additional_roster_hours && (
                                        <div className='custom-mb'>
                                          <span className='card-span'>Additional roster hours:</span>{val.additional_roster_hours}
                                        </div>
                                      )}
                                      <div className='custom-mb'>
                                        <span className='card-span'>YTD:</span>{val?.employer_to_date_totals.taxable_pay_ytd || errorNA}
                                      </div>
                                      <div className='custom-mb'>
                                        <span className='card-span'>Calculated YTD:</span>{val?.employer_to_date_totals.taxable_pay_ytd_calculated || errorNA}
                                        {val?.employer_to_date_totals.taxable_pay_ytd !== val?.employer_to_date_totals.taxable_pay_ytd_calculated && <span className='error-red'> (YTD mismatch)</span>}
                                      </div>
                                      <br />
                                    </div>
                                  )
                                })
                              }

                              <DataSection
                                title="Gambling websites"
                                data={gamblingAndUtlityData?.gambling_websites}
                                noDataMessage="No transactions related to gambling websites"
                                color='green'
                              />

                              <DataSection
                                title="Utility Payments"
                                data={gamblingAndUtlityData?.utility_payments}
                                noDataMessage="No transactions related to utility websites"
                                color="green"
                              />

                              <DataSection
                                title="Matched Net Pay Transactions from Payslip and Bank Statement"
                                data={netPayMatchData.data}
                                noDataMessage="Net pay transaction from payslip does not match in bank statement"
                                color="red"
                              />

                              {
                                <div className="custom-mb">
                                  {
                                    nameAndAddress?.length > 0 ? (
                                      nameAndAddress.map((val, index) => {
                                        const transactions = netPayMatchData[`bankStatement${index + 1}Transactions`];

                                        // let nameMatch, addressMatch;
                                        // if (val?.matching && val?.matching?.length > 0) {
                                        //   val?.matching?.forEach((val) => {
                                        //     console.log('valval', val)
                                        //     if (val?.key === 'name') {
                                        //       nameMatch = val?.match
                                        //     } else if (val?.key === 'address') {
                                        //       addressMatch = val?.match
                                        //     }
                                        //   })                                    
                                        // }

                                        // console.log('nameMatch', nameMatch, 'addressMatch', addressMatch)

                                        return (
                                          <div key={index}>
                                            <h4 className="custom-mb">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Bank Statement Data</h4>
                                            <p className="custom-mb">
                                              <span className='card-span'>Name on bank statement:</span>{val?.bank_statement_owner_name || errorNA}
                                            </p>
                                            <p className="custom-mb">
                                              <span className='card-span'>Account Number:</span>{val?.bank_statement_account_number || errorNA}
                                            </p>
                                            <p className="custom-mb">
                                              <span className='card-span'>Address on bank statement:</span>{val?.bank_statement_owner_address || errorNA}
                                            </p>
                                            <p className="custom-mb">
                                              <span className='card-span'>Transactions on bank statement:</span>
                                              {transactions > 40 ? transactions : <span className='error-red'>{transactions}</span>}
                                            </p>
                                            <br />
                                          </div>
                                        )
                                      })
                                    ) : (
                                      <span className='card-span' style={{ color: "red" }}>
                                        Bank Statement name and addresses not found
                                      </span>
                                    )
                                  }
                                </div>
                              }

                              {!isEmptyObject(p60Data) && (
                                <>
                                  <div className="custom-mb">
                                    <h4 className="custom-mb">P60 Data</h4>
                                    <p className="custom-mb"><span className='card-span'>Employee Forename:</span>{p60Data?.employee_forename || errorNA}</p>
                                    <p className="custom-mb"><span className='card-span'>Employee Surname:</span>{p60Data?.employee_surname || errorNA}</p>
                                    <p className="custom-mb"><span className='card-span'>National Insurance Number:</span>{p60Data?.national_insurance_number || errorNA}</p>
                                    <p className="custom-mb"><span className='card-span'>Tax code:</span>{p60Data?.tax_code || errorNA}</p>
                                    <p className="custom-mb"><span className='card-span'>Employer Fullname:</span>{p60Data?.employer_full_name || errorNA}</p>
                                    <p className="custom-mb"><span className='card-span'>Employer Address:</span>{p60Data?.employer_full_address || errorNA}</p>
                                    <p className="custom-mb"><span className='card-span'>Tax year to 5 april X:</span>{p60Data?.tax_year_to_5_april_x || errorNA}</p>
                                    <p className="custom-mb"><span className='card-span'>To Employee Address:</span>{p60Data?.to_employee_address || errorNA}</p>
                                  </div>
                                </>
                              )}

                              {!isEmptyObject(employmentContract) && (
                                <>
                                  <div className="custom-mb">
                                    <h4 className="custom-mb">Employment Contract</h4>
                                    <p className="custom-mb"><span className='card-span'>Start Date:</span>{employmentContract?.start_date || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Job title:</span>{employmentContract?.job_title || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Salary:</span>{employmentContract?.salary || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Employment Status:</span>{employmentContract?.employment_status || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Contract type:</span>{employmentContract?.contract_type || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Probation Period:</span>{employmentContract?.probation_period || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Employer Name:</span>{employmentContract?.employer_name || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Client Name:</span>{employmentContract?.client_name || errorNA}</p>
                                    <p className="custom-mb"><span className="card-span">Signed By Both Parties:</span>{employmentContract?.signed_by_both_parties || errorNA}</p>
                                  </div>
                                </>
                              )
                              }

                              <div className="custom-mb">
                                <div className='bs-transactions' onClick={() => navigate(`/case-transactions?id=${caseId}`)}>View Bank Statement Transactions</div>
                                <p className='card-span' style={{ marginTop: '10px', marginLeft: '5px' }}>Mortgage AI can make mistakes. Please double check responses.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </>
                ) : (
                  <h1 className='no-data'>No case data</h1>
                )}

              </div>
            </>

          )
      }
    </>
  )
}

export default CaseDetail