import React from 'react';
import './loader.css';

const Loader = ({ report }) => {
  return (
    <>
      <div className="ring">
        {report ? report : 'Loading'}
        <span className='ring-span'></span>
      </div>
      {report && <p className='text-generation'>Your report is being generated do not leave this page</p>}
    </>
  )
}

export default Loader