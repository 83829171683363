import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import { authToken } from '../../services/token';
import { requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { fetchCaseBankStatementTransactionsApi, updateBankStatementTransactionComment } from '../../services/apis/caseApi';
import Loader from '../../components/loader/Loader';
import './commonCases.css';
import { ENVS } from '../../utils/constant';

const CaseBsTransactions = () => {
    const [bankStatementData, setBankStatementData] = useState([]);
    const [filters, setFilters] = useState({ payment_method: '', amount: '', statement_type: '' });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = authToken();
    const loading = useSelector((state) => state.utilsObj.loading);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('id');

    const { REACT_APP_FRONTEND_IMG_PATH } = ENVS;

    useEffect(() => {
        fetchCaseBsTransactions();
    }, [filters]);

    const fetchCaseBsTransactions = async () => {
        try {
            dispatch(requestSent());
            const response = await fetchCaseBankStatementTransactionsApi(caseId, filters, token);

            if (response.status === 200) {
                dispatch(responseRecieved());
                setBankStatementData(response.data.data.rows);
            }
        } catch (error) {
            console.log('error=>', error);
            dispatch(responseRecieved(error));
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const handleResetFilters = () => {
        setFilters({ payment_method: '', amount: '', statement_type: '' });
    };

    const onEnterPress = async (e, val) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
          e.preventDefault();
          try {
            dispatch(requestSent());
            const response = await updateBankStatementTransactionComment(val?.id, {
                comment: e.target.value
            }, token)

            if (response.status === 200) {
                val.comment = e.target.value;
                dispatch(responseRecieved());
            }
          } catch (error) {
            dispatch(responseRecieved(error));
          }
        }
      }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <UserNavbar />
                    <div className="common-page">
                        <div className='flex-div'>
                            <img
                                src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`}
                                alt="back"
                                onClick={() => navigate(-1)}
                                style={{ cursor: 'pointer', marginRight: '8px' }}
                            />
                            <h1 className='main-subheading'>Case Bank Statement Transactions</h1>
                        </div>

                        <div className="bs-filter-box">
                            <select
                                name="payment_method"
                                value={filters.payment_method}
                                onChange={handleFilterChange}
                                className="bs-filter-select"
                            >
                                <option value="">Payment Method</option>
                                <option value="so">Standing Order</option>
                                <option value="dd">Direct Debits</option>
                            </select>

                            <select
                                name="amount"
                                value={filters.amount}
                                onChange={handleFilterChange}
                                className="bs-filter-select"
                            >
                                <option value="">Amount</option>
                                <option value="75">Paid out amounts ≥ 75</option>
                                <option value="250">Paid out amounts ≥ 250</option>
                            </select>
 
 
                            <select
                                name="statement_type"
                                value={filters.statement_type}
                                onChange={handleFilterChange}
                                className="bs-filter-select"
                            >
                                <option value="">Statement Type</option>
                                <option value="bank-statement">Bank statement</option>
                                <option value="credit-card-statement">Credit card statement</option>
                                <option value="joint-account-statement">Join account statement</option>
 1                           </select>


                            <button 
                                className="bs-filter-btn"
                                onClick={handleResetFilters}
                            >
                                Reset
                            </button>
                        </div>

                        {bankStatementData.length === 0 ? (
                            <div className="no-data">
                                <h1>No Transactions Found</h1>
                            </div>
                        ) : (
                            <>
                                <table className='transaction-table' style={{ margin: '18px 0' }}>
                                    <thead>
                                        <tr className='table-row'>
                                            <th className='table-head'>S.No.</th>
                                            <th className='table-head'>Date</th>
                                            <th className='table-head'>Payment Method</th>
                                            <th className='table-head'>Transaction Name</th>
                                            <th className='table-head'>Paid Out</th>
                                            <th className='table-head'>Paid In</th>
                                            <th className='table-head'>Statement Type</th>
                                            <th className='table-head'>Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bankStatementData.map((val, index) => (
                                            <tr className='table-row' key={index}>
                                                <td className="table-data">{index + 1}</td>
                                                <td className="table-data">{val?.date || 'NA'}</td>
                                                <td className="table-data">{val?.payment_method || 'NA'}</td>
                                                <td className="table-data">{val?.description || 'NA'}</td>
                                                <td className="table-data">{val?.paid_out}</td>
                                                <td className="table-data">{val?.paid_in}</td>
                                                <td className="table-data">{val?.statement_type}</td>
                                                <td title='Press Enter to save your comment after editing'>
                                                    <textarea onKeyDown={(event) => onEnterPress(event, val)} rows="5">{val?.comment}</textarea>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: 'center', padding: '12px 0' }}>
                                                <div className='card-span'>Mortgage AI can make mistakes. Please double check responses.</div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};


export default CaseBsTransactions